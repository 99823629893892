const { NEXT_PUBLIC_CDN_PREFIX } = process.env;

export const ICON_MOBILE_LOGO = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/logo.png`;
export const MOBILE_ICON_CART = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/cart.svg`;
export const MOBILE_ICON_DISCOVERY = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/earth.svg`;
export const MOBILE_ICON_STORE = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/store.svg`;
export const MOBILE_ICON_HOME = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/home.svg`;
export const MOBILE_ICON_COUPON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/coupon.svg`;
export const MOBILE_ICON_BACK = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/back.svg`;
export const MOBILE_ICON_SEARCH = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/search.svg`;
export const MOBILE_ICON_RANK_DIAMOND = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/account/rank/diamond_rank.svg`;
export const MOBILE_ICON_RANK_GOLD = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/account/rank/gold_rank.svg`;
export const MOBILE_ICON_RANK_PLATIUM = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/account/rank/platium_rank.svg`;
export const MOBILE_ICON_RANK_SILVER = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/account/rank/silver_rank.svg`;
export const MOBILE_ICON_TS_POINT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/ts_point.svg`;
export const MOBILE_ICON_WALLET = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/wallet.svg`;
export const BUSINESS_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/business_icon.svg`;
export const CHAT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/chat_icon.svg`;
export const CONTACT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/contact_icon.svg`;
export const M_EYE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/eye_icon.svg`;
export const HAND_SHAKE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/hand_shake_icon.svg`;
export const HEART_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/heart_icon.svg`;
export const INSIGHT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/insight_icon.svg`;
export const ORDER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/order_icon.svg`;
export const PHONE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/phone_icon.svg`;
export const POINT_ACCUMULATING_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/point_accumulating_icon.svg`;
export const QUESTION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/question_icon.svg`;
export const RESEARCH_INFO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/research_info_icon.svg`;
export const SIGN_OUT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/sign_out_icon.svg`;
export const TRUCK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/truck_icon.svg`;
export const MOBILE_USER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/user_icon.svg`;
export const MOBILE_VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/userinfo/voucher_icon.svg`;
export const MOBILE_ICON_SEARCH_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/search_white.svg`;
export const MOBILE_ICON_TRATHUONG = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/mobile/IconTraThuong.svg`;
// export const ARROW_PRICE_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/arrow_price_icon.png`;
export const COUPON_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/coupon_icon.svg`;
// export const GIFT_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/gift_icon.svg`;
export const DAILY_PROMOTION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/home/daily_promotion_icon.svg`;
export const DRUG_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/home/drug.svg`;
// export const EVENT_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/home/event_icon.svg`;
// export const FLASHSALE_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/home/flashsale_icon.svg`;
export const NEWPRD_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/home/newprd_icon.svg`;
// export const SELLER_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/home/seller_icon.svg`;
export const INGREDIENT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/ingredient_icon.svg`;
export const PRODUCT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/product_icon.svg`;
// export const STORE_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/store_icon.png`;
// export const ADDITIONAL_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/tags/additional_icon.png`;
// export const ARROW_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/tags/arrow_icon.png`;
// export const DOCUMENT_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/tags/document_icon.png`;
// export const INGRADIENT_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/tags/ingradient_icon.png`;
export const LIKE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/tags/like_icon.svg`;
export const NEW_PRODUCT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/tags/new_product.svg`;
// export const TARGET_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/tags/target_icon.png`;
// export const DOCUMENT_ICON2 = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/userinfo/document_icon.svg`;
// export const EYE_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/userinfo/eye_icon.svg`;
export const INFO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/userinfo/info_icon.svg`;
// export const LEVEL_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/userinfo/level_icon.svg`;

export const MOBILE_ICON_SORT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/sort.svg`;
export const MOBILE_ICON_FILTER = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/filter.svg`;
export const MOBILE_ICON_ALERT_WARNING = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/alert/warning.svg`;
export const MOBILE_ICON_ALERT_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/alert/success.svg`;
// export const MOBILE_ICON_ARROW_DOWN = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/arrow_down.svg`;
export const MOBILE_DOUBLE_ARROW_RIGHT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/store/double_arrow_right.svg`;
export const MOBILE_ICON_SLIDE_ARROW_RIGHT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/store/slider_right_arrow.svg`;
export const MOBILE_ICON_SLIDE_ARROW_LEFT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/store/slider_left_arrow.svg`;
export const MOBILE_ICON_SORT_HOT_SALE = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/hot-sale.svg`;
export const MOBILE_ICON_SORT_NEWEST = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/newest.svg`;
export const MOBILE_ICON_SORT_PRICE_ASC = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/price-asc.svg`;
export const MOBILE_ICON_SORT_PRICE_DESC = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/price-desc.svg`;
export const MOBILE_ICON_SORT_NAME_ASC = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/name-asc.svg`;
export const MOBILE_ICON_SORT_NAME_DESC = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/products/sort/name-desc.svg`;

// export const MOBILE_ICON_DOTS = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/icons/mobile/chat/dot.svg`;
export const MOBILE_ICON_AVATAR_CS = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/chat/avatar_cs.svg`;
export const MOBILE_ICON_CHAT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/chat/icon_chat.svg`;
export const MOBILE_ICON_EMPTY_CHAT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/chat/icon_empty_message.svg`;
export const MOBILE_ICON_SENT = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/chat/sent.svg`;
export const MOBILE_ICON_SENDING = `${NEXT_PUBLIC_CDN_PREFIX || ""}/icons/mobile/chat/sending.svg`;

export const MOBILE_CONVERSATION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ""}/static/mobile/icons/icon_chat.svg`;
